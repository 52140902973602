.rc-notification {
    position: fixed;
    z-index: 2;
    display: flex;
    max-height: 100vh;
    padding: 10px;
    align-items: flex-end;
    width: 340px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    box-sizing: border-box;
    pointer-events: none;
    flex-direction: column;
    color: rgb(211, 211, 211);
    font-size: 16px;
}

@media (max-width: 742px) {
    .rc-notification {
        margin-top: 47px;
        font-size: 12px;
    }
}

.rc-notification-top,
.rc-notification-topLeft,
.rc-notification-topRight {
    top: 0;
}

.rc-notification-bottom,
.rc-notification-bottomRight,
.rc-notification-bottomLeft {
    bottom: 0;
}

.rc-notification-bottomRight,
.rc-notification-topRight {
    right: 0;
}

.rc-notification-notice {
    position: relative;
    display: block;
    box-sizing: border-box;
    line-height: 1.5;
    width: 100%;
}

.rc-notification-notice-wrapper {
    pointer-events: auto;
    position: relative;
    display: block;
    box-sizing: border-box;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.75) 0px 20px 30px -10px;
    margin: 0 0 20px;
    background: #333333d8;
    width: 300px;
}

@media (max-width: 742px) {
    .rc-notification-notice-wrapper {
        width: 240px;
    }
}

.rc-notification-notice-content {
    padding: 15px 35px 15px 15px;
}

.rc-notification-notice-content .tag {
    color: #969696;
    font-size: 10px;
}

.rc-notification-notice-close {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #929292;
    font-weight: 700;
    font-size: 16px;
    line-height: 0.5;
    cursor: pointer;
    opacity: 1;
}

.rc-notification-notice-close-x:after {
    content: '×';
}

.rc-notification-notice-close:hover {
    text-decoration: none;
    opacity: 1;
    filter: alpha(opacity=100);
}

.rc-notification-notice-progress {
    position: absolute;
    left: 3px;
    right: 3px;
    border-radius: 1px;
    overflow: hidden;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 30px;
    display: block;
    inline-size: 100%;
    block-size: 2px;
    border: 0;
}

.rc-notification-notice-progress,
.rc-notification-notice-progress::-webkit-progress-bar {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 30px;
}

.rc-notification-notice-progress::-moz-progress-bar {
    background-color: #13a700;
    border-radius: 30px;
}

.rc-notification-notice-progress::-webkit-progress-value {
    background-color: #13a700;
    border-radius: 30px;
}

.rc-notification-fade {
    overflow: hidden;
    transition: all 0.3s;
}

.rc-notification-fade-appear-prepare {
    pointer-events: none;
    opacity: 0 !important;
}

.rc-notification-fade-appear-start {
    transform: translateX(100%);
    opacity: 0;
}

.rc-notification-fade-appear-active {
    transform: translateX(0);
    opacity: 1;
}

.rc-notification-stack>.rc-notification-notice-wrapper {
    transition: all 0.3s;
    position: absolute;
    top: 12px;
    opacity: 1;
}

.rc-notification-stack>.rc-notification-notice-wrapper:not(:nth-last-child(-n + 3)) {
    opacity: 0;
    right: 34px;
    width: 252px;
    overflow: hidden;
    color: transparent;
    pointer-events: none;
}

.rc-notification-stack>.rc-notification-notice-wrapper:nth-last-child(1) {
    right: 10px;
}

.rc-notification-stack>.rc-notification-notice-wrapper:nth-last-child(2) {
    right: 18px;
    width: 284px;
    color: transparent;
    overflow: hidden;
}

.rc-notification-stack>.rc-notification-notice-wrapper:nth-last-child(3) {
    right: 26px;
    width: 268px;
    color: transparent;
    overflow: hidden;
}

.rc-notification-stack.rc-notification-stack-expanded>.rc-notification-notice-wrapper:not(:nth-last-child(-n + 1)) {
    opacity: 1;
    width: 300px;
    right: 10px;
    overflow: unset;
    color: inherit;
    pointer-events: auto;
}

.rc-notification-stack.rc-notification-stack-expanded>.rc-notification-notice-wrapper::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -16px;
    width: 100%;
    height: calc(100% + 32px);
    background: transparent;
    pointer-events: auto;
    color: #ffffff;
}

.rc-notification-stack.rc-notification-bottomRight>.rc-notification-notice-wrapper {
    top: unset;
    bottom: 12px;
}