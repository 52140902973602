.prompt-form {
    margin-top: 0.5em;
}

.prompt-form input {
    display: inline-block;
    color: rgb(182, 182, 182);
    font-size: 16px;
    border-radius: 30px;
    border: none;
    margin-top: 0.5em;
    margin-left: 0.5em;
    background: #3b3b3bbd;
    cursor: pointer;
    width: 165px;
}
.prompt-form input:focus {
    color: rgb(182, 182, 182);
    background: #3b3b3bbd;
    outline: none;
    box-shadow: none;
}

.prompt-form input::placeholder {
    color: rgb(139, 139, 139);
    opacity: 1;
}

.prompt-form input:-ms-input-placeholder {

    color: rgb(139, 139, 139);
}

.prompt-form input::-ms-input-placeholder {

    color: rgb(139, 139, 139);
}